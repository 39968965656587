import { ChannelRepository } from '@amityco/js-sdk'
import { Modal, ParagraphText as Text, Button } from '@thryvlabs/maverick'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router'
import useToast from '../../hooks/Toasts/useToast'
import { toggleOpenFlyout } from '../../redux/actions/Flyout'
import { getStorageItem } from '../../helpers/environment_helpers'

export const LeaveChannelModal = ({
  channelId,
  channelType,
  userId,
  isActiveUser,
  channelMembersCount,
  buttonType = 'secondary',
  disabled = false,
}) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { triggerToast } = useToast()

  //Get the ID of the account owner
  const OAuthRole = localStorage.getItem('OAuthRole')
  let ownerId
  if (OAuthRole === 'owner') {
    ownerId = getStorageItem('Thryv_Staff_Id')
  }

  const handleLeaveChannel = async () => {
    if (channelType === 'conversation') return
    if (ownerId === userId && channelMembersCount > 1) {
      triggerToast(
        'As the admin for this channel, you are unable to leave. You can always delete this channel if you prefer.',
        'failure',
      )
      return
    }
    if (isActiveUser) {
      if (channelMembersCount === 1) {
        const wasDeleted = await ChannelRepository.updateChannel({
          channelId: channelId,
          tags: ['deleted'],
        })
        if (wasDeleted) {
          dispatch(toggleOpenFlyout(false))
          triggerToast('Left channel successfully.', 'success')
          history.push('/home')
        }
      } else {
        const hasLeft = await ChannelRepository.leaveChannel(channelId)
        if (hasLeft) {
          triggerToast('Left channel successfully.', 'success')
          history.push('/home')
        }
      }
    } else {
      try {
        const isRemoved = await ChannelRepository.removeMembers({
          channelId: channelId,
          userIds: [userId],
        })
        if (isRemoved) {
          triggerToast('User removed successfully.', 'success')
        }
      } catch (e) {
        triggerToast('Failed to remove user.', 'failure')
        console.log('Failed to remove user', e)
      }
    }
  }

  if (disabled) return <></>

  return (
    <Modal
      variant="default"
      title={
        isActiveUser
          ? 'Are you sure you want to leave this channel?'
          : 'Are you sure you want to remove this user from this channel?'
      }
      // Need to override btnText with actual Button to get the correct button color.
      btnText={
        isActiveUser && buttonType === 'text' ? (
          <Button
            variant="text"
            level={1}
            style={{ color: '#ff5000', fontWeight: 'bold' }}
            data-testid="channel-option-leave"
          >
            Leave
          </Button>
        ) : isActiveUser ? (
          'LEAVE'
        ) : (
          'REMOVE'
        )
      }
      btnType={buttonType}
      btnLevel={buttonType === 'text' && 1}
      footer
      footerPrimary={isActiveUser ? 'LEAVE' : 'REMOVE'}
      primaryAction={handleLeaveChannel}
      primaryFooterVariant="dark"
      footerCancel
      primaryClose
      cancelAction={() => console.log('cancel clicked')}
      modalTestId="channel-modal-confirm-leave"
    >
      <Text variant="reg" color="thryv-neutral" className="modal-paragraph-text">
        {isActiveUser
          ? 'You will be removed from this channel.'
          : 'This user will be removed from this channel.'}
      </Text>
    </Modal>
  )
}
