import { useState } from 'react'
import { Modal, ParagraphText, Popover, Button, Input } from '@thryvlabs/maverick'
import { ChannelRepository } from '@amityco/js-sdk'
import useToast from '../../hooks/Toasts/useToast'
import { useHistory } from 'react-router-dom'
import { LeaveChannelModal } from '../LeaveChannelModal'
import { getStorageItem } from '../../helpers/environment_helpers'
import { httpPost, httpPut } from '../../helpers/http_helpers'
import useCharLimitHandler from '../../hooks/Common/useCharLimitHandler'

export default function EditDeleteChannel({
  channelId,
  displayName,
  setIsOpen,
  channelMembersCount,
  isChannelDeleted,
  channelType = 'community',
}) {
  const activeUserId = getStorageItem('ActiveUserId')
  const channelNamePlaceholder = displayName ?? channelId ?? ''
  const [newChannelName, setNewChannelName] = useState(channelNamePlaceholder)
  const [error, setError] = useState(null)
  const { triggerToast } = useToast()
  const history = useHistory()
  const thryv_id = getStorageItem('Thryv_Id') || getStorageItem('thryv_id')
  const { charsRemaining } = useCharLimitHandler(newChannelName)

  const handleChange = (event) => {
    setNewChannelName(event.target.value)
  }

  const deleteChannel = async () => {
    try {
      httpPost(`deletechannel/${channelId}`, { thryv_id }).then(async (result) => {
        await ChannelRepository.updateChannel({
          channelId: channelId,
          tags: ['deleted'],
        })
        triggerToast('Channel Removed.', 'success')
        // closing modal
        const closeButton = document.getElementsByClassName('bg-none border-none')[0]
        if (closeButton) closeButton.click()
      })
    } catch (e) {
      triggerToast('Failed to Delete Channel.', 'failure')
    }
  }

  const updateChannelDisplayName = async (newChannelName) => {
    if (newChannelName.trim() === '') {
      setError('Channel name cannot be empty.')
      return
    }
    try {
      const body = {
        channel_id: channelId,
        changes: {
          alias: newChannelName,
        },
      }

      httpPut(`updatechannel/${thryv_id}`, body)
        .then(async (result) => {
          await ChannelRepository.updateChannel({
            channelId: channelId,
            displayName: newChannelName,
          })
          setError('')
          triggerToast('Channel Updated.', 'success')
          //closing modal
          const closeButton =
            document.getElementsByClassName('bg-none border-none')[0]
          if (closeButton) closeButton.click()
        })
        .catch((error) => {
          if (error && error.message) {
            setError(error.message)
          }
        })
    } catch (e) {
      triggerToast('Failed to Update Channel.', 'failure')
    }
  }

  const onCancel = () => {
    setError('')
    setNewChannelName('')
  }

  return (
    <div
      style={{
        position: 'absolute',
        marginTop: '47px',
        marginLeft: '-66px',
        width: '165px',
        zIndex: 5,
      }}
    >
      <Popover>
        {channelType !== 'conversation' ? (
          <>
            <Modal
              variant="default"
              title="Edit Channel"
              btnText={
                <Button
                  variant="text"
                  level={1}
                  style={{ color: 'black', fontWeight: 'bold' }}
                  data-testid="channel-option-edit"
                >
                  Edit
                </Button>
              }
              btnType="text"
              btnLevel={1}
              footer
              footerPrimary="Save Changes"
              primaryAction={() => updateChannelDisplayName(newChannelName)}
              primaryFooterVariant="dark"
              footerCancel
              onClose={onCancel}
              cancelAction={onCancel}
              onOpen={() => {
                setNewChannelName(displayName)
                setError('')
              }}
              modalTestId="channel-modal-confirm-edit"
            >
              <ParagraphText variant="lg" color="thryv-neutral">
                Edit this channel's name.
              </ParagraphText>
              <div className={'mt-5 input-container'}>
                <Input
                  className="w-100"
                  type="text"
                  placeholder="Channel Name"
                  name="channelName"
                  withLabel
                  labelType="floating"
                  variant="default"
                  value={newChannelName}
                  onChange={handleChange}
                  maxLength="25"
                />
                <span
                  className={`char-counter ${charsRemaining === 0 ? 'red' : ''}`}
                >
                  {charsRemaining}
                </span>
                {error && error.length > 0 && (
                  <span style={{ color: 'red' }}> {error}</span>
                )}
              </div>{' '}
              <div className={'mt-5'}></div>
            </Modal>{' '}
            <br />
            <br />
          </>
        ) : null}
        <Modal
          variant="default"
          title="Are you sure you want to delete this channel?"
          altBtn={
            <Button
              variant="text"
              level={1}
              style={{ color: '#ff5000', fontWeight: 'bold' }}
              data-testid="channel-option-delete"
            >
              Delete
            </Button>
          }
          btnLevel={1}
          footer
          action
          btnActionText="Delete Channel"
          btnAction={() => {
            deleteChannel()
            setTimeout(() => {
              setIsOpen(false)
              history.push('/home')
            }, 300)
          }}
          primaryFooterVariant="dark"
          footerCancel
          cancelAction={() => console.log('cancel clicked')}
          modalTestId="channel-modal-confirm-delete"
        >
          <ParagraphText variant="lg" color="thryv-neutral">
            Deleting this channel will delete all activity.
          </ParagraphText>
        </Modal>
        {!isChannelDeleted && channelType !== 'conversation' ? (
          <>
            <br />
            <br />
            <div>
              <LeaveChannelModal
                channelId={channelId}
                channelType={'community'}
                userId={activeUserId}
                isActiveUser={true}
                channelMembersCount={channelMembersCount}
                buttonType="text"
              />
            </div>
          </>
        ) : null}
      </Popover>
    </div>
  )
}
