import { useEffect, useState } from 'react';
import axios from 'axios';

export default function useUserActiveChannels(thryvStaffId) {
    const [channels, setChannels] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_URL}api/teamchat/userchannels/${thryvStaffId}`)
            .then((res) => {
                setChannels(res.data.channels);
                setLoading(false);
            })
            .catch((err) => {
                setError(err);
            });
    }, []);

    return { channels, loading, error };
}