import axios from 'axios'

const makeHttpCall = async (method, url, data) => {
  if (!method) {
    throw new Error('[method] is required')
  }
  if (!url) {
    throw new Error('[url] is required')
  }
  method = String(method).toLowerCase()
  const validMethods = ['get', 'put', 'post', 'delete']
  if (validMethods.indexOf(method) === -1) {
    throw new Error('Invalid http method')
  }

  try {
    let response
    if (method === 'get' || method === 'delete') {
      response = await axios[method](
        `${process.env.REACT_APP_API_URL}api/teamchat/${url}`,
      )
    } else {
      response = await axios[method](
        `${process.env.REACT_APP_API_URL}api/teamchat/${url}`,
        data,
      )
    }
    return response
  } catch (error) {
    throw new Error(error.response?.data || error.response?.statusText)
  }
}
/**
 * Make http GET request to API
 * @param {string} url
 * @returns Promise
 */
export const httpGet = (url) => {
  return makeHttpCall('get', url)
}

/**
 * Make http POST request to API
 * @param {string} url
 * @param {Object} data
 * @returns Promise
 */
export const httpPost = (url, data) => {
  return makeHttpCall('post', url, data)
}

/**
 * Make http PUT request to API
 * @param {string} url
 * @param {Object} data
 * @returns
 */
export const httpPut = (url, data) => {
  return makeHttpCall('put', url, data)
}

/**
 * Make http DELETE request to API
 * @param {string} url
 * @returns
 */
export const httpDelete = (url) => {
  return makeHttpCall('delete', url)
}
