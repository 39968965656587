import axios from 'axios';
import { API_URL } from '../app.config';

export const getResource = async (resource, messageId) => {
    const resources = {
        messageReactions: `api/teamchat/reactions/${messageId}`,
    };

    try {
        const { data } = await axios.get(`${API_URL}${resources[resource]}`);
        return data;
    } catch (error) {
        console.error('Error fetching resource:', error);
        throw error;
    }
};