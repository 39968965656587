import { useEffect, useState } from 'react';

const useCharLimitHandler = (displayName) => {
  const [charsRemaining, setCharsRemaining] = useState(25);

  useEffect(() => {
    setCharsRemaining(25 - (displayName?.length ?? 0));
  }, [displayName]);

  return { charsRemaining };
};

export default useCharLimitHandler;
