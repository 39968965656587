import './index.css'
import { Avatar } from '@thryvlabs/maverick'
import { getAvatarColorByName, getFirstAndLastName } from '../../utils/utils'
import { LeaveChannelModal } from '../../LeaveChannelModal'
import { getStorageItem } from '../../../helpers/environment_helpers'
import { useSocketStateContext } from '../../../contexts/SocketContext'
import { updateAmityUser } from '../../../helpers/db_amity_sync_helpers'
import { useEffect, useState } from 'react'
import { httpGet } from '../../../helpers/http_helpers'

export const ChannelMember = ({
  channelId,
  channelType,
  member,
  channelMembersCount,
  channelCreatorStaffId,
}) => {
  const [displayName, setDisplayName] = useState(member?.user?.displayName || '')
  const [email, setEmail] = useState(
    member?.user?.metadata?.email || 'No email available.',
  )

  const avatarUrl = member?.user?.avatarCustomUrl
  const userId = member?.userId

  const socketState = useSocketStateContext()

  const activeUser = getStorageItem('ActiveUserId')
  const isActiveUser = activeUser === userId

  const thryv_id = getStorageItem('Thryv_Id')
  const isStaffChannel = channelId === `staff_${thryv_id}` || channelId === 'Staff'

  const thryvStaffIdRegex = /^[a-z0-9].{15}$/m

  // If displayName or email are incorrect, check DB and try to update
  useEffect(() => {
    if (
      !email ||
      email === 'No email available.' ||
      !displayName ||
      thryvStaffIdRegex.test(displayName)
    ) {
      httpGet(`getuser/${userId}`).then((res) => {
        const user = res.data.getUser
        let updateRequired = false
        if ((!email || email === 'No email available.') && user?.email) {
          setEmail(user.email)
          updateRequired = true
        }
        if (
          (!displayName || thryvStaffIdRegex.test(displayName)) &&
          user?.display_name
        ) {
          setDisplayName(user.display_name)
          updateRequired = true
        }
        if (updateRequired)
          updateAmityUser(
            userId,
            member?.user?.metadata,
            user.display_name,
            user.email,
          )
      })
    }
  }, [])

  // Hide "Remove" button if channel member is not the current user but is the creator of the channel
  const isMemberChannelCreator = !isActiveUser && userId === channelCreatorStaffId

  const showRemoveLeaveButtons =
    channelType !== 'conversation' && !isStaffChannel && !isMemberChannelCreator

  return (
    <>
      <div className="channel-member-item">
        <div className="icon-container">
          <div className="avatar">
            <Avatar
              className={'ml-[10px] RefClientListAvatar'}
              imageUrl={avatarUrl}
              variant={avatarUrl ? 'image' : 'name'}
              style={
                avatarUrl
                  ? {}
                  : { backgroundColor: getAvatarColorByName(displayName) }
              }
              name={getFirstAndLastName(displayName)}
              size="default"
            />
            <div
              className={
                socketState.onlineUsers.includes(userId) ? 'logged-in' : 'logged-out'
              }
            ></div>
          </div>
        </div>
        <div
          className={`channel-member ${
            !showRemoveLeaveButtons || channelType === 'conversation'
              ? 'type-conversation'
              : ''
          }`}
        >
          <div className="channel-member-display-items">
            <span className="display-name RefClientListLabelText">
              {isActiveUser ? ' You' : displayName}
            </span>
            <div className="channel-member-email client-email status text-utility-link-info">
              <span>{email}</span>
            </div>
          </div>
          {showRemoveLeaveButtons && (
            <LeaveChannelModal
              channelId={channelId}
              channelType={channelType}
              userId={userId}
              isActiveUser={isActiveUser}
              channelMembersCount={channelMembersCount}
              disabled={isMemberChannelCreator}
            />
          )}
        </div>
      </div>
    </>
  )
}
