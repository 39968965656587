import React, { useEffect, useState } from 'react'
import '../Main/index.css'
import { ChannelRepository, ChannelType } from '@amityco/js-sdk'
import { ChannelBrowser } from '../../components/ChannelBrowser'
import { ChatRoom } from '../../components/ChatRoom'
import { WelcomeToConversation } from '../../components/WelcomeToConversation'
import { ChatFlyout } from '../../components/ChatFlyout'
import { ReferenceContainer } from '../../components/Reference'
import { Redirect, Route, Switch, useHistory, useRouteMatch } from 'react-router-dom'
import { TeamChatSettings } from '../../components/TeamChatSettings'
import { httpGet } from '../../helpers/http_helpers'
import { getStorageItem, removeStorageItem } from '../../helpers/environment_helpers'
import { AppHeader } from '../AppHeader'
import useIsInFrame from '../../hooks/Layout/useIsInFrame'
import Search from '../Search'
import SocketProvider from '../../contexts/SocketContext'
import { createAccountStaffChannel } from '../../helpers/activation_helpers'
import { CHAT_ROOM_TYPE } from './constants'
import axios from 'axios'
import { API_URL } from '../../app.config'
import { useSelector, useDispatch } from 'react-redux'
import useSyncCCTeamMembers from '../../hooks/Settings/useSyncCCTeamMembers'
import { setClientList } from '../../redux/actions/ClientList'
import { setUserList } from '../../redux/actions/Conversations'

function Main(props) {
  const thryv_id = getStorageItem('Thryv_Id')
  const thryv_staff_id = getStorageItem('ActiveUserId')
  const [activeChannelId, setActiveChannelId] = useState(`staff_${thryv_id}`)
  const { path: currentParentPath } = useRouteMatch()
  const { isInFrame } = useIsInFrame()
  const [showWelcome, setShowWelcome] = useState(false)
  const dispatch = useDispatch()
  const { access_token, directory_code } = useSelector((state) => state.ActiveUser)

  useEffect(() => {
    // If staff channel can be found, set as active channel. If not, create and join new staff channel.
    const setStaffChannel = async () => {
      const channel = await httpGet(`getchannel/staff_${thryv_id}`)
      if (channel?.data?.getChannel[0]?.channel_id) {
        setActiveChannelId(channel.data.getChannel[0].channel_id)
      } else {
        const newChannelId = await createAccountStaffChannel(
          thryv_id,
          thryv_staff_id,
          directory_code,
        )
        if (newChannelId) setActiveChannelId(newChannelId)
      }
    }
    setStaffChannel()
  }, [])

  useEffect(() => {
    axios
      .get(`${API_URL}api/teamchat/activities/${thryv_staff_id}`)
      .then((res) => {
        setShowWelcome(res?.data?.length === 0)
      })
      .catch((error) =>
        console.error('Error while fetching activity records', error),
      )
  }, [thryv_id, thryv_staff_id])

  useEffect(() => {
    const fetchAllClients = async (page = 1, collection = []) => {
      try {
        const requestPayload = {
          thryv_id: thryv_id,
          access_token: access_token,
          page: page,
          dirCode: directory_code,
        }
        const request = await axios.post(
          `${API_URL}api/teamchat/getAllClients`,
          requestPayload,
        )
        const resList = request?.data?.data?.clients ?? []
        if (resList.length > 0) {
          collection = [...collection, ...resList]
          dispatch(setClientList({ clientList: collection }))
          page += 1
          return await fetchAllClients(page, collection)
        } else {
          return collection
        }
      } catch (err) {
        console.log('Fetching clients error.')
      }
    }
    fetchAllClients()
      .then((res) => {
        dispatch(setClientList({ clientList: res }))
      })
      .catch(() => {
        console.log('Error fetching clients.')
      })
  }, [])

  useEffect(() => {
    // Can use below thryv_id when running local
    if (!thryv_id) {
      thryv_id = 'tohl6loedb6ws716'
    }

    httpGet(`getallusers/${thryv_id}`).then((res) =>
      dispatch(setUserList({ userList: res.data.getUsers })),
    )
  }, [thryv_id])

  const teamChatMainProps = {
    ...props,
    activeChannel: activeChannelId,
    showWelcome: true,
  }

  return (
    <SocketProvider>
      {!isInFrame && <AppHeader currentUserInfo={props.currentUserInfo} />}
      <Switch>
        <Route path={`${currentParentPath}/main`}>
          <TeamChatMain {...teamChatMainProps} />
        </Route>
        <Route path={`${currentParentPath}/teamChatSettings`}>
          <TeamChatSettings someProps={{ props }} />
        </Route>
        <Route path={currentParentPath}>
          <Redirect to={`${currentParentPath}/main`} />
        </Route>
      </Switch>
    </SocketProvider>
  )
}

const TeamChatMain = ({ activeChannel, showWelcome }) => {
  const history = useHistory()
  const { path: currentParentPath } = useRouteMatch()
  const [channelId, setChannelId] = useState(activeChannel)
  const { isInFrame } = useIsInFrame()

  useEffect(() => {
    if (showWelcome) history.push('/home/main/welcome')
  }, [showWelcome])

  useEffect(() => {
    if (channelId) {
      const liveChannel = ChannelRepository.getChannel(channelId)
      liveChannel.once('dataUpdated', (channel) => {
        if (channel.type !== 'conversation') {
          ;(async () => {
            await ChannelRepository.joinChannel({
              channelId,
              type: ChannelType.Community,
            })
            await ChannelRepository.startReading(channelId).catch((err) =>
              console.log(err.message),
            )
          })()
        }
      })
    }
  }, [channelId])

  useSyncCCTeamMembers(channelId)

  const handleChange = async (value) => {
    try {
      let newChannelId = value.channelId || value
      if (channelId !== newChannelId) {
        await ChannelRepository.startReading(newChannelId).catch((err) =>
          console.log(err.message),
        )
        // Using try/catch here because if the starting channelId doesn't exist in amity, this will throw error,
        // But the start/stop reading functionlity is necessary for quickly switching between channels
        try {
          await ChannelRepository.stopReading(channelId)
        } catch (err) {
          console.warn('Failed to stop reading channelId', channelId)
        }
        setChannelId(newChannelId)
      }
    } catch (err) {
      console.log(err.message)
    }
  }

  return (
    <div className={'flex flex-row'} data-private>
      <div
        className={`Main font-semibold font-montserrat ${
          isInFrame ? 'max-vertical-height' : 'calculated-vertical-height'
        }`}
      >
        <ChannelBrowser
          activeChannelId={channelId}
          onChange={handleChange}
          setChannelId={setChannelId}
        />
        <Switch>
          <Route exact path={`${currentParentPath}/messages`}>
            <ChatRoom
              chatRoomType={CHAT_ROOM_TYPE.NORMAL}
              channelId={channelId}
              setChannelId={setChannelId}
            />
          </Route>
          <Route
            exact
            path={`${currentParentPath}/new-conversation`}
            render={() => (
              <ChatRoom
                chatRoomType={CHAT_ROOM_TYPE.NEW_CONVERSATION}
                channelId={channelId}
                setChannelId={setChannelId}
              />
            )}
          />
          {showWelcome && (
            <Route
              exact
              path={`${currentParentPath}/welcome`}
              render={() => <WelcomeToConversation />}
            />
          )}
          <Route exact path={`${currentParentPath}/search`}>
            <Search setChannelId={setChannelId} />
          </Route>
          <Route path={currentParentPath}>
            <Redirect to={`${currentParentPath}/messages`} />
          </Route>
        </Switch>
      </div>
      <div
        className={`flyout-reference-panel ${
          isInFrame ? 'max-vertical-height' : 'calculated-vertical-height'
        }`}
      >
        {channelId && (
          <ChatFlyout
            ChildComponent={ReferenceContainer}
            parentProps={{ channelId }}
          />
        )}
      </div>
    </div>
  )
}

export default Main
