export const getHighlightedText = (text, highlight) => {
  const parts = text.split(new RegExp(`(${highlight})`, 'gi'));
  return (
    <span>
      {' '}
      {parts.map((part, i) => (
        <span
          key={i}
          style={
            part.toLowerCase() === highlight.toLowerCase()
              ? {
                  fontWeight: 'bold',
                  backgroundColor: '#FFEDE5',
                  borderRadius: '5px',
                  padding: '.2rem',
                  display: 'inline-block'
                }
              : {}
          }
        >
          {part}
        </span>
      ))}{' '}
    </span>
  );
};
