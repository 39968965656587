import axios from 'axios';

export const updateUserRoleAmity = async (thryv_staff_id) => {
  console.log('Updating user role to super-moderator');
  if (!thryv_staff_id) return;

  const data = {
      userId: thryv_staff_id,
      roles: ['super-moderator']
  };

  const response = await axios.put(
      `${process.env.REACT_APP_API_URL}api/teamchat/updateAmityUser`,
      data
  );

  return response.data ?? null;
};

export const updateAmityUser = async (
  userId,
  metadata,
  displayName = null,
  email = null,
) => {

  if (!userId || !metadata) return;
  if (!displayName && !email) return;

  const data = {
      userId,
      metadata,
      displayName,
      email
  };

  const response = await axios.put(
      `${process.env.REACT_APP_API_URL}api/teamchat/updateAmityUser`,
      data
  );

  return response.data ?? null;
};