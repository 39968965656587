import React, {
  useContext,
  createContext,
  useReducer,
  useEffect,
  useRef,
  useCallback,
} from 'react'
import { getStorageItem, setStorageItem } from '../helpers/environment_helpers'
import { io } from 'socket.io-client'
import axios from 'axios'
import { API_URL } from '../app.config'

const INITIAL_STATE = { onlineUsers: [], socket: null, typers: {}, statuses: {} }

const SocketStateContext = createContext()
const SocketDispatchContext = createContext()

const socketReducer = (state, action) => {
  switch (action.type) {
    case 'onlineUsers': {
      return { ...state, onlineUsers: action.payload }
    }
    case 'saveSocket': {
      return { ...state, socket: action.payload }
    }
    case 'typingEvent': {
      return {
        ...state,
        typers: {
          ...state.typers,
          [action.payload.userId]: { ...action.payload, time: Date.now() },
        },
      }
    }
    case 'updateTypers': {
      return {
        ...state,
        typers: action.payload,
      }
    }
    case 'statusChanged': {
      return {
        ...state,
        statuses: { ...state.statuses, [action.payload.userId]: action.payload },
      }
    }
    case 'allUserStatuses': {
      return {
        ...state,
        statuses: action.payload,
      }
    }
    default:
      return state
  }
}

const useSocketStateContext = () => {
  const context = useContext(SocketStateContext)
  if (!context)
    throw new Error('useSocketStateContext must be used within SocketStateContext!')
  return context
}

const useSocketDispatchContext = () => {
  const context = useContext(SocketDispatchContext)
  if (!context)
    throw new Error(
      'useSocketDispatchContext must be used within SocketDispatchContext!',
    )
  return context
}

const timeoutTime = 1500
let away_status = ''

const SocketProvider = ({ children }) => {
  const [socketState, socketDispatch] = useReducer(socketReducer, INITIAL_STATE)
  const userId = getStorageItem('ActiveUserId')

  const typingTimeoutRef = useRef(null)

  const startTypingTimeout = useCallback(() => {
    clearTimeout(typingTimeoutRef.current)
    typingTimeoutRef.current = null

    const currentTime = Date.now()
    const newTypers = Object.entries(socketState.typers).reduce(
      (obj, [id, memberData]) => {
        if (currentTime - memberData.time < timeoutTime) {
          obj[id] = memberData
        }
      },
      {},
    )

    socketDispatch({ type: 'updateTypers', payload: newTypers })
    if (Object.keys(newTypers) > 0) {
      typingTimeoutRef.current = setTimeout(startTypingTimeout, timeoutTime)
    }
  }, [socketDispatch])

  useEffect(() => {
    if (userId) {
      const socket = io(`${API_URL}`, {
        transports: ['websocket'],
      })

      axios
        .get(`${API_URL}api/teamchat/getstatus/${userId}`)
        .then((res) => {
          if (res?.data?.convertedBufferObject?.status_icon) {
            socket.emit('statusChanged', {
              userId: userId,
              id: res?.data?.convertedBufferObject?.status_icon,
              value: res?.data?.convertedBufferObject?.status_value,
            })
          }

          away_status = res?.data?.convertedBufferObject?.away_status
        })
        .catch((error) =>
          console.log('Error form the socket statusChanged event', error),
        )

      socketDispatch({ type: 'saveSocket', payload: socket })

      socket.on('typing', (payload) => {
        if (userId === payload.userId) return

        socketDispatch({ type: 'typingEvent', payload })

        if (typingTimeoutRef.current === null)
          typingTimeoutRef.current = setTimeout(startTypingTimeout, timeoutTime)
      })

      socket.on('onlineUsers', (allOnlineUsers) => {
        let uniqueChars = [...new Set(allOnlineUsers)]
        socketDispatch({ type: 'onlineUsers', payload: uniqueChars })
      })

      socket.on('statusChanged', (data) => {
        socketDispatch({ type: 'statusChanged', payload: { data } })
      })

      socket.on('allUserStatuses', (data) => {
        socketDispatch({ type: 'allUserStatuses', payload: { data } })
      })

      socket.on('connect', () => {
        if (
          away_status !== 'undefined' &&
          away_status !== '' &&
          away_status !== null
        ) {
          if (parseInt(away_status) !== 0) {
            socket.emit('awayStatus', userId)
            setStorageItem('myawaystatus', 1)
          } else {
            socket.emit('user_online', userId)
            setStorageItem('myawaystatus', 0)
          }
        } else {
          socket.emit('user_online', userId)
          setStorageItem('myawaystatus', 0)
        }
      })

      socket.on('offlineUsers', (users100) => {})

      socket.on('disconnect', () => {
        socket.emit('user_disconnect', userId)
      })
    }
  }, [userId])

  return (
    <SocketDispatchContext.Provider value={socketDispatch}>
      <SocketStateContext.Provider value={socketState}>
        {children}
      </SocketStateContext.Provider>
    </SocketDispatchContext.Provider>
  )
}

export default SocketProvider
export { useSocketStateContext, useSocketDispatchContext }
