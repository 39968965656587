import React, { useState, useEffect, useRef } from "react";
import {
  MessageRepository, FileType,
  MemberFilter, ChannelRepository, ChannelType
} from '@amityco/js-sdk';
import { Uploader } from "../Uploader";
import { Attachment } from "../Attachment";
import { FileIcon, SmilyIcon } from "../Icons";
import { Toasts, withModal, Icon, Avatar } from "@thryvlabs/maverick";
import { EmojiPicker } from "../EmojiPicker";
import { Mention, MentionsInput } from "react-mentions";
import { REFERENCE_ELEMENT_TYPE, ReferencePanel } from "../ReferencePanel";
import { useDispatch, useSelector } from "react-redux";
import { setBothInputMessageValue } from "../../redux/actions/MessageInput";
import { httpPost } from "../../helpers/http_helpers";
import { getAvatarColorByName, getFirstAndLastName } from "../utils/utils";
import { setRefreshReferences } from "../../redux/actions/References";
import styled from "styled-components";
import axios from 'axios';
import "./index.css";
import { getStorageItem } from "../../helpers/environment_helpers";
import { vNotification } from "../../api/vNotification";
import { CommandBarNotificationApi } from "../../api/commandbar-notification";
import { useSocketStateContext } from "../../contexts/SocketContext";
import { ImageOptionsModal } from "./ImageOptionsModal";
import { getUniqueListById } from "../NewConversation";
import { useHistory } from "react-router-dom";
import { CHAT_ROOM_TYPE } from "../../pages/Main/constants";

const createUniqueKey = () => `${Math.random().toString(16)}.${Date.now()}`;
// regex used to manage texts that contains references. Ex.: @[INVOICE #0000151 {test} ](yyy::INVOICE::https://go.thryv.com/app/invoices/yyy)
const MENTION_REGEX = /@\[(.+?)]\((.+?)\)/;

const PlainMessageComposerContainer = withModal(MessageComposer, ReferencePanel)
export const MessageComposerContainer = styled(PlainMessageComposerContainer)`
  padding: 0 0 1rem 0;
`;

function MessageComposer({ channelId, showModal, editMessage, messageListElementRef, setChannelId, chatRoomType }) {
  const [mentions, setMentions] = useState([]);
  const [selectedMentionees, setSelectedMentionees] = useState([]);
  const [channelMemberArray, setChannelMemberArray] = useState([]);
  const [uniqueKey, setUniqueKey] = useState(createUniqueKey());
  const [file, setFile] = useState();
  const [showEdit, setShowEdit] = useState(false);
  const [onDesktop, setOnDesktop] = useState(true);
  const [invalidInputToast, setInvalidInputToast]  = useState({
    hide: true,
    message: 'Invalid input...',
    type: 'warning'
  });
  const userList = useSelector(s => s.Conversations.userList);
  const newConvoUsers = useSelector(s => s.Conversations.newConvoUsers);
  const messageInputValue = useSelector(state => state.MessageInput.messageInputValue);
  const directory_code = useSelector(state => state.ActiveUser.directory_code);
  const dispatch = useDispatch();
  const messageComposer = useRef();
  const collectMembers = useRef({ channel: null });
  const notifiedMembers = useRef({});
  
  const socketState = useSocketStateContext();
  const history = useHistory();

  const activeUserId = localStorage.getItem("ActiveUserId");
  const activeUserDisplayName= localStorage.getItem("ActiveUserIdDisplayName");

  useEffect(() => {
    const onResize = () => {
      if (messageComposer?.current && window.innerWidth > 425) {
        messageComposer.current.style.marginBottom = '0';
      }
  
      if (window.innerWidth < 425) {
        setOnDesktop(!onDesktop);
      } else {
        setOnDesktop(onDesktop);
      }
    }

    if (window.innerWidth < 425) {
      setOnDesktop(!onDesktop);
    }
    window.addEventListener('resize', onResize, false);
  }, [messageComposer]);

  useEffect(() => {
    if (!channelId) return;

    const controller = new AbortController();

    axios.get(`${process.env.REACT_APP_API_URL}api/teamchat/channelmembers/${channelId}`, {
        signal: controller.signal 
    })
    .then((res) => {
      if (res.data) setChannelMemberArray(res.data);
    })
    .catch((e) => {
        if (e.message !== 'canceled') {
            console.error('Error fetching channel members:', e);
        }
    });

    return () => controller.abort();
}, [channelId]);

  useEffect(() => {
    let thryv_id = getStorageItem('Thryv_Id');
    const controller = new AbortController();
    (async () => {
      const { data } = await axios.get(`${process.env.REACT_APP_API_URL}api/teamchat/getallusers/${thryv_id}`, {
        signal: controller.signal
      });

      let validOptions =
        channelMemberArray.length > 0
          ? data.getUsers.filter((option) =>
              channelMemberArray.map((member) => member.userId).includes(option.thryv_staff_id)
            )
          : data.getUsers;

      setMentions(() =>
        validOptions.map(({ thryv_staff_id, display_name, avatar_url }) => {
          const actionLink = 'LINK'; // todo: replace with redirection or action link
          return {
            id: `${thryv_staff_id}::${REFERENCE_ELEMENT_TYPE.THRYV_USER}::${actionLink}`,
            display: display_name,
            thryv_staff_id,
            avatar_url
          };
        })
      );

    })().catch((e) => {
      if (e.message !== 'canceled') {
        console.error('Response error while fetching DB channel users. ', e.message);
      }
    });

    return () => controller.abort();
  }, [channelMemberArray]);

  useEffect(() => {
    if (!editMessage) {
      setShowEdit(false)
    } else {
      setShowEdit(true)
    }
    resetState();
    dispatch(setBothInputMessageValue({ plainMessageInputValue: '', messageInputValue: editMessage?.data?.text }));
  }, [editMessage]);

  function resetState () {
    setFile();
    dispatch(setBothInputMessageValue({ plainMessageInputValue: '', messageInputValue: ''}));
    setUniqueKey(createUniqueKey());
    setSelectedMentionees([]);
  };

  function resetStateTwo () {
    dispatch(setBothInputMessageValue({ plainMessageInputValue: '', messageInputValue: '' }));
    setShowEdit(false);
    setSelectedMentionees([]);
  };

  const sendNewMessage = (text = '') => {
    let newMessageLiveObj;
    let mentionees = [];

    if(selectedMentionees.length > 0) {
      mentionees = [{
        type: "user",
        userIds: selectedMentionees.map(m => m.id.split('::')[0]),
      }]
    }

    if (!file) {
      newMessageLiveObj = MessageRepository.createTextMessage({
        channelId,
        text: text,
        mentionees,
      });
    } else if (file.type === FileType.Image) {
      newMessageLiveObj = MessageRepository.createImageMessage({
        channelId,
        imageId: file.fileId,
        caption: text,
        mentionees
      });
    } else if (file.type === FileType.File) {
      newMessageLiveObj = MessageRepository.createFileMessage({
        channelId,
        fileId: file.fileId,
        caption: text,
        mentionees
      });
    }
    
    newMessageLiveObj.once('dataUpdated', v => {
      const currentRef = messageListElementRef.current;
      if (currentRef.scrollHeight > currentRef.scrollTop) {
        currentRef.scrollIntoView(false);
        currentRef.scrollTop = currentRef.scrollHeight;
      };
      newMessageLiveObj.dispose()
    })

    if (MENTION_REGEX.test(text)) {
      const referenceId = MENTION_REGEX.exec(text)[2].split(/,|:/)[0];
      const reference = {
        thryv_id :  getStorageItem("Thryv_Id"),
        thryv_staff_id : getStorageItem("Thryv_Staff_Id"),
        object: MENTION_REGEX.exec(text)[0],
        reference_id: referenceId,
        parent_id: channelId
      };

      // FOR LOCAL ONLY
      if (!reference['thryv_id']) reference['thryv_id'] = 'tohl6loedb6ws716';
      if (!reference['thryv_staff_id']) reference['thryv_staff_id'] = 'gemvej6mq8y8ufsk';

      httpPost('newreference', reference)
      .then(res => {})
      .catch(err => console.log(err));
    }
    dispatch(setRefreshReferences(true))
    resetState();
  };

  const newConversationMessageHandler = e => {
    if (newConvoUsers?.length < 1) {
      showInvalidInvalidToast('Please add some members to proceed...');
    } else if (file && (!messageInputValue || messageInputValue?.trim() === '')) {
      /* CASE: When file is attached but no message input is there. */
      sendNewConversationMessage(e);
    } else if (!messageInputValue || messageInputValue?.trim() === '') {
      showInvalidInvalidToast('Type something to proceed...');
    } else {
      sendNewConversationMessage(e);
    }
  };

  const sendNewConversationMessage = e => {
    e.preventDefault();
    const thryv_staff_id = getStorageItem("Thryv_Staff_Id");
    const text = messageInputValue;
    const currentUser = {
      display_name: userList.find((v) => v.thryv_staff_id === thryv_staff_id)?.display_name,
      id: thryv_staff_id
    };
    const newConvoUsersList = getUniqueListById([currentUser, ...newConvoUsers], "id");
    let userIdsForNewConvo = [];
    let displayNamesForNewConvo = [];

    for (let i = 0; i < newConvoUsersList.length; i++) {
      userIdsForNewConvo.push(newConvoUsersList[i]?.id);
      if (thryv_staff_id === newConvoUsersList[i]?.id && newConvoUsersList.length === 2) continue;
      displayNamesForNewConvo.unshift(newConvoUsersList[i]?.display_name);
    }
    const convoMembersDetailedList = userList.filter((user) =>
        userIdsForNewConvo.includes(user.thryv_staff_id));

    /** Upload image file for avatar **/
    const coverImageUrl = userList.find((user) =>
        user.thryv_staff_id === userIdsForNewConvo.at(-1)
    )?.avatar_url;

    const liveChannel = ChannelRepository.createChannel({
      type: ChannelType.Conversation,
      userIds: userIdsForNewConvo,
      displayName: displayNamesForNewConvo.join(", "),
      metadata: { coverImageUrl, convoMembersDetailedList }
    });
    
    liveChannel.once("dataUpdated", (e) => {
      const channelId = e.channelId;
      if (!file) {
        MessageRepository.createTextMessage({
          channelId,
          text
        });
      } else if (file.type === FileType.Image) {
        MessageRepository.createImageMessage({
          channelId,
          imageId: file.fileId,
          caption: text
        });
      } else if (file.type === FileType.File) {
        MessageRepository.createFileMessage({
          channelId,
          fileId: file.fileId,
          caption: text
        });
      }
      // If this chat was previously deleted, remove deleted tag so user can re-join.
      // 'restored' tag needed, as empty array will not overwrite 'deleted' tag
      if (e.tags.includes('deleted')) {
        ChannelRepository.updateChannel({
          channelId: channelId,
          tags: ['restored']
        })
      }
      setChannelId(channelId);
      resetState();
    });
  };

  const normalMessageHandler = () => {
    if (file && (!messageInputValue || messageInputValue?.trim() === '')) {
      sendNewMessage();
      return;
    } else if (!messageInputValue || messageInputValue?.trim() === '') {
      showInvalidInvalidToast('Type something to proceed...');
      return;
    }

    if (showEdit) {
      updateMessage(messageInputValue);
    } else {
      sendNewMessage(messageInputValue);
    }

    notifiedMembers.current = {}

    const channelInfo= ChannelRepository.getChannel(channelId);
    const channelDisplayName= channelInfo.model.displayName;

    collectMembers.current.channel = ChannelRepository.queryMembers({
      channelId: channelId,
      memberships: [MemberFilter.Member]
    });

    collectMembers.current.channel.once("dataUpdated", (data) => {
      data.forEach((member) => {
        if (member.userId !== activeUserId) {
          vNotification(member.userId, activeUserDisplayName, channelDisplayName, messageInputValue, channelId, directory_code)
          CommandBarNotificationApi(activeUserDisplayName,channelDisplayName, member.userId, messageInputValue)
        }
      })
    })
  };

  const sendMessage = (e) => {
    e.preventDefault();
    if (!channelId)
      newConversationMessageHandler(e);
    else
      normalMessageHandler();
    if (chatRoomType === CHAT_ROOM_TYPE.NEW_CONVERSATION) history.push('/home/main/messages');
    return () => collectMembers.current.channel.dispose();
 };
    
  const updateMessage = async (text) => {
    try {
      await MessageRepository.updateMessage({
        messageId: editMessage.messageId,
        data: {
          text: text
        },
        metadata: {
          isEdited: true
        }
      })
    } catch(error) {
      console.log('Error while EDITING message: ', error)
    }
    resetStateTwo();
  };

  const onMessageInputChangeHandler = (e, value, plainValue, mentions) => {
    if (plainValue !== "") {
      socketState.socket.emit("typing",
        {
          channelId, 
          userId: getStorageItem("ActiveUserId"),
          userName: getStorageItem("ActiveUserIdDisplayName"),
        })
    }
    setSelectedMentionees(mentions);
    dispatch(setBothInputMessageValue({ plainMessageInputValue: plainValue, messageInputValue: value }));
  }

  const showInvalidInvalidToast = (message, type = 'warning') => {
    setInvalidInputToast({
      hide: false, message: message, type: type
    });
    setTimeout(() => {
      setInvalidInputToast({
        hide: true, message: message, type: type
      });
    }, 4000)
  }

  const handleKeyUp = e => {
    const selectionStart = e.target.selectionStart
    const lastCharacter = String.fromCharCode(e.target.value?.charCodeAt(selectionStart - 1))
    if (lastCharacter === '#') {
      showModal()
    }
  }

  return (
    <>
      {showEdit && (
        <div className="MessageInputActionToast d-flex justify-content-between items-center">
          <span>
            <Icon
              type="solid"
              variant="triangleExclamation"
              height={14}
              className="ml-2"
            /> You are editing this message.
            </span>
          <button>
            <Icon height={14} type="solid" variant="circleX" onClick={resetStateTwo} />
          </button>
        </div>
      )}
      <div ref={messageComposer} className="MessageComposer">
        {file && <Attachment file={file} onDelete={resetState} />}
        {!invalidInputToast.hide &&
          <Toasts
            toastMessage={invalidInputToast.message}
            toastType={invalidInputToast.type}
            hideToast={invalidInputToast.hide}
          />}
        <form onSubmit={sendMessage}>
          <div className="chat-input-container">
            <MentionsInput
              id={'message-input-element'}
              className={'mentionWrapper bg-transparent'}
              placeholder={'type @ to mention and # to reference'}
              value={messageInputValue || ''}
              onKeyPress={e => {
                if (e.key === 'Enter' && !e.shiftKey) {
                  sendMessage(e);
                }
              }}
              onKeyUp={handleKeyUp}
              onChange={onMessageInputChangeHandler}
            >
              <Mention
                trigger="@"
                className="mention-client-activity"
                data={mentions}
                displayTransform={(id, display) => `${display}`}
                appendSpaceOnAdd={true}
                renderSuggestion={(entry, search, highlightedDisplay, index, focused) => {

                  return (
                    <div className={`convo-members-suggestion-item`} key={entry?.id}>
                      <div className="avatar">
                        <div className = "icon-container">
                          <Avatar
                            className={'ml-[10px] composer-item-avatar'}
                            variant={entry.avatar_url ? 'image' : 'name'}
                            size="default"
                            style={entry.avatar_url ? {} : {backgroundColor: getAvatarColorByName(entry.display)}}
                            imageUrl={entry.avatar_url}
                            name={getFirstAndLastName(entry.display)}
                            />
                          <div className={socketState.onlineUsers.includes(entry['thryv_staff_id']) ? 'logged-in-composer' : 'logged-out-composer'}></div>
                        </div>
                      </div>
                      <span className={'display-name RefPanelLabelText'}>{entry.display}</span>
                    </div>
                  )
                }}
              />
            </MentionsInput>
            <div className="uploaders-box">
              <div className="Uploaders">
                <Uploader key={`${uniqueKey}#2`} accept="*/*" onChange={setFile}>
                  <FileIcon className="icon-file" />
                </Uploader>
                <EmojiPicker>
                  <SmilyIcon className="icon-emojis" />
                </EmojiPicker>
                { file?.type === FileType.Image ?
                    <ImageOptionsModal
                        modalProps={{ file, channelId, messageInputValue, sendMessage, resetState }}
                        parentProps={{}}/> :
                    <button id="send-save-btn">
                      {showEdit ? 'Save' : 'Send'}
                    </button>
                }
               </div>
               <div className="uploaders-limiter"></div>
            </div>
          </div>
        </form>
      </div>
    </>
  );
}
