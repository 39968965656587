import React, { useState, useRef } from 'react'
import './index.css'
import QrCode from '../../assets/images/teamchat_qr_code.png'
import Hello from '../../assets/images/CommandCenterImages_TeamChat_Illustration1.png'
import AddChannels from '../../assets/images/CommandCenterImages_TeamChat_Illustration2.png'
import previewPoster from '../../assets/images/previewPoster.png'
import playIcon from '../../assets/images/play.svg'
import { useHistory } from 'react-router-dom'

export const WelcomeToConversation = ({ setChannelId }) => {
  const history = useHistory()
  const [isPlaying, setPlaying] = useState(false)
  const videoRef = useRef(null)

  // Firefox adds their own play button to videos, so we check if we're in Firefox and hide ours.
  const isInFirefox = navigator.userAgent.toLowerCase().indexOf('firefox') > -1

  const handleVideoPlaying = () => {
    const video = videoRef.current
    if (video.paused) {
      setPlaying(true)
      if (videoRef.current.requestFullscreen) {
        videoRef.current.requestFullscreen()
      } else if (videoRef.current.mozRequestFullScreen) {
        /* Firefox */
        videoRef.current.mozRequestFullScreen()
      } else if (videoRef.current.webkitRequestFullscreen) {
        /* Chrome, Safari and Opera */
        videoRef.current.webkitRequestFullscreen()
      } else if (videoRef.current.msRequestFullscreen) {
        /* IE/Edge */
        videoRef.current.msRequestFullscreen()
      }
      video.play()
    } else {
      setPlaying(false)
      video.pause()
    }
  }

  const handleEnterStaffChannel = () => {
    history.push('/home/main/messages')
  }

  return (
    <div className="welcome-convo-container">
      <div className="welcome-convo-header-container row align-items-center ps-2 ps-md-4 ps-xl-0 py-3">
        <div className="welcome-labels-container col-12 col-xl-6">
          <p className="welcome-first-label d-block text-wrap h-auto">
            Welcome to your first <br /> TeamChat Channel:{' '}
            <span style={{ color: '#FF5000' }}>#Staff</span>
          </p>
          <p className="welcome-second-label my-3">
            This channel is for everyone! <br /> We’ve automatically added your
            teammates.
          </p>
          <button
            className="rounded-pill px-4 py-3 border-0 welcome-staff-button text-white"
            onClick={handleEnterStaffChannel}
          >
            Send A Message
          </button>
        </div>
        <div className="welcome-video-container col-12 col-xl-6 h-100 ">
          <div className="welcome-video pb-2 d-flex flex-column justify-content-xl-center align-items-center align-items-sm-start align-items-xl-center h-100 pt-4 pt-xl-0">
            <div className="position-relative video-player">
              <video
                controls
                ref={videoRef}
                poster={previewPoster}
                onClick={handleVideoPlaying}
                className="h-100 w-100"
              >
                <source src="https://cdn.labs.thryv.com/Thryv_CommandCenter_2023_3.mp4" />
              </video>
              {!isPlaying && (
                <span
                  className="position-absolute video-player-icon"
                  role="button"
                  onClick={handleVideoPlaying}
                >
                  {!isInFirefox && <img src={playIcon} alt="play welcome video" />}
                </span>
              )}
            </div>
            <p className="welcome-video-text text-wrap ">
              Conversations don’t have to stop even when your team is on the go with
              <span className="welcome-video-text-color">
                {' '}
                TeamChat’s Mobile App now available on App Store and Google Play
              </span>
            </p>
            <button
              className="btn btn-link text-center d-block d-sm-none mt-3 text-decoration-none"
              onClick={handleVideoPlaying}
            >
              {!isPlaying ? 'Play Video' : 'Pause Video'}
            </button>
          </div>
        </div>
      </div>
      <div className="welcome-convo-footer-container">
        <p className="get-started-text text-center text-md-start text-wrap">
          Get started with a few more steps:
        </p>
        <div className="welcome-convo-footer-inside-container d-flex flex-column flex-md-row flex-wrap align-items-center align-items-md-start justify-content-md-between me-auto ps-1 ps-xl-3 ps-xxl-5">
          <div className="welcome-convo-footer-action-qr flex-1">
            <img src={QrCode} alt="QR Code" />
            <div className="img-text">
              <p className="action-header-first">Download the app</p>
              <p className="action-message-first">
                Teamchat is now available on App Store and Google Play
              </p>
            </div>
          </div>
          <div className="welcome-convo-footer-action-hello flex-1">
            <img src={Hello} alt="hello message" />
            <div className="img-text">
              <p className="action-header-second">Send your first message</p>
              <p className="action-message-second">
                Say hello and get that chat going!
              </p>
            </div>
          </div>
          <div className="welcome-convo-footer-action-channels flex-1">
            <img src={AddChannels} alt="Add more channels" />
            <div className="img-text">
              <p className="action-header-third">Add more channels</p>
              <p className="action-message-third">
                Organize channels by job, client or project!
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
