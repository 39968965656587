import React, { useState, useEffect, useRef } from 'react'
import { useDispatch } from 'react-redux'
import { ChannelRepository, ChannelType, MemberFilter } from '@amityco/js-sdk'
import { ChannelMemberCountIcon } from '../Icons'
import { Icon, Pill, Button, Badge } from '@thryvlabs/maverick'
import InviteChannelMembersModal from '../InviteChannelMembersModal'
import './index.css'
import { getStorageItem } from '../../helpers/environment_helpers'
import EditDeleteChannel from './EditDeleteChannel'
import { httpGet } from '../../helpers/http_helpers'
import useTotalUnreadCount from '../../hooks/UnreadCount/useTotalUnreadCount'
import { setDisplayChannelBrowser } from '../../redux/actions/channelBrowser'
import { toggleOpenFlyout } from '../../redux/actions/Flyout'

const useOutsideClick = (ref, callback) => {
  const handleClick = (e) => {
    if (ref.current && !ref.current.contains(e.target)) {
      callback()
    }
  }

  useEffect(() => {
    document.addEventListener('click', handleClick)
    return () => {
      document.removeEventListener('click', handleClick)
    }
  }, [])
}

export function ChannelHeader({ channelId }) {
  const ref = useRef()
  const collection = useRef()
  const [channel, setChannel] = useState()
  const [channelMembers, setChannelMembers] = useState([])
  let [isOpen, setIsOpen] = useState(false)
  const [channelStaffId, setchannelStaffId] = useState(0)
  const { totalUnreadCount } = useTotalUnreadCount()
  const dispatch = useDispatch()
  const OAuthRole = getStorageItem('OAuthRole')
  let ActiveUserId = getStorageItem('ActiveUserId')

  useEffect(() => {
    const liveObject = ChannelRepository.getChannel(channelId)
    liveObject.on('dataUpdated', setChannel)
    if (liveObject.model) setChannel(liveObject.model)
    return () => liveObject.dispose()
  }, [channelId])

  useEffect(() => {
    collection.current = ChannelRepository.queryMembers({
      channelId,
      memberships: [MemberFilter.Member],
    })
    collection.current.on('dataUpdated', (newModels) => {
      setChannelMembers(
        newModels.filter(
          (member) =>
            member?.userId !== '_admin_admin_teamchat' &&
            member?.userId !== '_admin_teamchat prod-1649169462274d3cba9c-admin',
        ),
      )
    })
    if (collection.current?.models) {
      setChannelMembers(
        collection.current.models.filter(
          (member) =>
            member?.userId !== '_admin_admin_teamchat' &&
            member?.userId !== '_admin_teamchat prod-1649169462274d3cba9c-admin',
        ),
      )
    }
    return () => collection.current.dispose()
  }, [channelId])

  useEffect(() => {
    // Channel creator staff ID only needed if user is not admin, channel exists and is not deleted (editable)
    if (OAuthRole !== 'owner' && channel && !channel?.tags.includes('deleted')) {
      httpGet(`getchannel/${channelId}`).then((res) => {
        if (res.data.getChannel.length === 0) {
          setchannelStaffId(0)
        } else {
          setchannelStaffId(res.data.getChannel[0].thryv_staff_id)
        }
      })
    }
  }, [OAuthRole, channel, channelId])

  useOutsideClick(ref, () => {
    setIsOpen(false)
  })

  const handleClickMemberIcon = () => {
    dispatch(toggleOpenFlyout(true))
  }

  // Generate channel name for one-on-one chats and multi-member chats
  let oneOnOneDisplayName
  let multiMemberDisplayName
  let isInactive = false
  let conversationMembers = channel?.metadata?.convoMembersDetailedList?.filter(
    (item) => item !== null,
  )

  if (conversationMembers?.length === 2) {
    const endMemberDetail = conversationMembers.find(
      (v) => v.thryv_staff_id !== getStorageItem('Thryv_Staff_Id'),
    )
    oneOnOneDisplayName = endMemberDetail?.display_name
    isInactive = endMemberDetail?.status === 0
  } else if (conversationMembers?.length > 2) {
    const chatMembers = conversationMembers.map((member) => member.display_name)
    const lastMember = chatMembers.pop()
    multiMemberDisplayName = `${chatMembers.join(', ')} and ${lastMember}`
  }

  const showChannelEditOptions =
    (ActiveUserId === channelStaffId || OAuthRole === 'owner') &&
    !channelId.toLowerCase().startsWith('staff') &&
    !channel?.tags.includes('deleted')

  const shortenDisplayName = (displayName) => {
    if (!displayName?.length) return displayName
    if (
      channel?.type === ChannelType.Conversation &&
      (displayName.length > 55 || displayName?.split(' ').length > 7)
    ) {
      return displayName?.split(',').splice(0, 3).join(',') + '...'
    } else {
      return displayName
    }
  }

  return (
    <div ref={ref} className="ChannelHeader">
      <div
        className="users-icon-container"
        onClick={() => dispatch(setDisplayChannelBrowser(true))}
      >
        <Icon className="icon-users" type="solid" variant="users" />
        <Pill className="icon-pill" variant="primary" number={totalUnreadCount} />
      </div>
      <div className={'channel-header__name-member-count'}>
        <h3>
          {oneOnOneDisplayName ??
            shortenDisplayName(multiMemberDisplayName) ??
            shortenDisplayName(channel?.displayName) ??
            channel?.channelId}
        </h3>
        {isInactive && <Badge variant="negative" badgeText="Inactive" />}
        {channelMembers.length > 0 && (
          <span className="member-count" data-testid="channel-detail">
            {channelMembers.length}
            <span className="cursor-pointer" onClick={handleClickMemberIcon}>
              <ChannelMemberCountIcon className="member-icon" />
            </span>
          </span>
        )}
      </div>
      <div className={'channel-header__invite-ellipsis'}>
        {channel?.type !== ChannelType.Conversation &&
          !channel?.tags?.includes('deleted') && (
            <div className="invite-btn">
              <InviteChannelMembersModal
                buttonText={'Invite'}
                channelId={channelId}
              />
            </div>
          )}
        {(showChannelEditOptions || channel?.type === 'conversation') && (
          <Button
            variant="text"
            level={1}
            onClick={() => setIsOpen((isOpen) => !isOpen)}
            data-testid="channel-options"
          >
            <Icon variant={'horizontalEllipsis'} type={'regular'} />
          </Button>
        )}
        {isOpen && (
          <EditDeleteChannel
            channelId={channelId}
            displayName={channel.displayName}
            setIsOpen={setIsOpen}
            channelMembersCount={channelMembers.length}
            isChannelDeleted={channel?.tags?.includes('deleted')}
            channelType={channel?.type}
          />
        )}
      </div>
    </div>
  )
}
