import { useState, useEffect } from 'react';
import { getStorageItem } from '../../helpers/environment_helpers';
import { ChannelRepository, ChannelType, ChannelFilter, ChannelSortingMethod } from '@amityco/js-sdk';
import axios from 'axios';

export default function useAccountActiveChannels() {
    const [channels, setChannels] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const thryvId = getStorageItem('Thryv_Id');

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_URL}api/teamchat/activechannels/${thryvId}`)
            .then(response => {
                setChannels(response.data.channels);
                setLoading(false);
            })
            .catch(err => {
                console.error('Error fetching channels:', err);
                setError(err);
            });
    }, [thryvId]);

    // Fallback to fetch admin's channels if primary fetch fails
    useEffect(() => {
        if (error) {
            const liveCollection = ChannelRepository.queryChannels({
                types: [ChannelType.Standard, ChannelType.Community, ChannelType.Live],
                filter: ChannelFilter.Member,
                excludeTags: ['deleted'],
                sortBy: ChannelSortingMethod.FirstCreated
            });

            liveCollection.on('dataUpdated', (models) => {
                setChannels(models);
                setLoading(false);
            });

            liveCollection.on('loadingStatusChanged', (models) => {
                if (liveCollection && 
                    liveCollection.loadingStatus === 'loaded' && 
                    liveCollection.hasMore) {
                    liveCollection.nextPage();
                }
            });

            liveCollection.on('dataError', (err) => {
                setError({ code: err?.code, message: err?.message });
            });

            return () => liveCollection.dispose();
        }
    }, [error]);

    return { channels, loading, error };
}