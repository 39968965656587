import { useEffect, useState, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getStorageItem } from '../../helpers/environment_helpers'
import { MessageRepository } from '@amityco/js-sdk'
import {
  updateChannelUnreadMap,
  updateChatUnreadMap,
} from '../../redux/actions/unreadCount'
import { displayBrowserNotification } from '../../helpers/notifications_helpers'

export default function useChannelUnreadCount(channelId, unreadCount, channelType) {
  const dispatch = useDispatch()
  const activeUserId = getStorageItem('ActiveUserId')
  const collection = useRef()
  const [trueUnreadCount, setTrueUnreadCount] = useState(unreadCount)
  const { channelUnreadMap, chatUnreadMap } = useSelector(
    (state) => state.unreadCount,
  )

  const lastUnreadCount =
    channelType === 'channel'
      ? channelUnreadMap[channelId] ?? null
      : chatUnreadMap[channelId] ?? null

  const updateUnreadMap = (newCount) => {
    channelType === 'channel'
      ? dispatch(updateChannelUnreadMap({ channelId, unreadCount: newCount }))
      : dispatch(updateChatUnreadMap({ chatId: channelId, unreadCount: newCount }))
  }

  const updateUnreadCount = (messages) => {
    let trueUnreadMessages = messages
      .sort((a, b) => b.createdAt - a.createdAt)
      .slice(0, unreadCount)
      .filter((message) => message.userId !== activeUserId)
    setTrueUnreadCount(trueUnreadMessages.length)
    updateUnreadMap(trueUnreadMessages.length)
  }

  useEffect(() => {
    if (unreadCount === lastUnreadCount) {
      return
    } else if (unreadCount === 0) {
      setTrueUnreadCount(0)
      if (lastUnreadCount === null || (lastUnreadCount && lastUnreadCount > 0)) {
        updateUnreadMap(0)
      }
    } else if (unreadCount >= 20) {
      updateUnreadMap(unreadCount)
      setTrueUnreadCount(unreadCount)
    } else {
      collection.current = MessageRepository.queryMessages({
        channelId,
      })

      collection?.current?.on('dataUpdated', (models) => {
        updateUnreadCount(models)
      })
      ;(async () => {
        await displayBrowserNotification(collection?.current?.models)
      })()

      return () => collection?.current?.dispose()
    }
  }, [channelId, unreadCount, channelType, activeUserId, lastUnreadCount, dispatch])

  return { trueUnreadCount }
}
